"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _store = _interopRequireDefault(require("../store"));
var _auth = require("@/utils/auth");
var _user = require("@/api/user");
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * permission: true               需要进行权限鉴定
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  hidden: true,
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: 'Dashboard',
      icon: 'dashboard'
    }
  }]
},
// 资源配置
{
  path: '/resource',
  component: _layout.default,
  alwaysShow: true,
  name: 'resource',
  meta: {
    title: '资源配置',
    icon: 'my-resource',
    affix: true
  },
  children: [{
    path: 'channelsmanage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/channelsmanage/index'));
      });
    },
    name: 'channelsmanage',
    meta: {
      title: '渠道组',
      icon: 'my-appmanage',
      affix: true
    }
  }, {
    path: 'centerchannelsedit',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/channelsmanage/centerChannelsEdit.vue'));
      });
    },
    name: 'centerchannelsedit',
    meta: {
      title: '中台渠道组编辑',
      affix: true
    }
  }, {
    path: 'fans',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pageconfig/index'));
      });
    },
    name: 'fans',
    meta: {
      title: '导粉配置',
      icon: 'fensi',
      affix: true
    }
  }, {
    path: 'shop',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pageconfig/index'));
      });
    },
    name: 'goods',
    meta: {
      title: '商城配置',
      icon: 'guanggaoguanli',
      affix: true
    }
  },
  //  测算模型权重分发
  {
    path: 'pattern',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pattern'));
      });
    },
    meta: {
      title: '权重分发配置',
      icon: 'my-list',
      affix: true
    },
    noCache: true,
    children: [{
      path: '/',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/pattern/config'));
        });
      },
      name: 'patternConfig',
      meta: {
        title: '',
        affix: true
      },
      noCache: true
    }, {
      path: 'list',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/pattern/list'));
        });
      },
      name: 'patternList',
      meta: {
        title: '',
        affix: true
      },
      noCache: true
    }, {
      path: 'sceneCodeList',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/pattern/sceneCodeList'));
        });
      },
      name: 'patternSceneCodeList',
      meta: {
        title: '',
        affix: true
      },
      noCache: true
    }]
  }]
}, {
  path: '/config',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pageconfig/config'));
    });
  },
  name: 'config',
  hidden: true,
  meta: {
    title: '页面配置'
  }
}, {
  path: '/library',
  component: _layout.default,
  alwaysShow: true,
  meta: {
    title: '资源库',
    icon: 'my-library',
    affix: true
  },
  children: [
  // {
  //     path: 'uidesign',
  //     component: () => import('@/views/modulestore/index'),
  //     name: 'uidesign',
  //     meta: {
  //         title: '组件库',
  //         icon: 'my-module',
  //         affix: true
  //     }
  // },
  // {
  //     path: 'imagestore',
  //     component: () => import('@/views/imagestore/index'),
  //     name: 'imagestore',
  //     meta: {
  //         title: '图片库',
  //         icon: 'my-images',
  //         affix: true
  //     }
  // },
  {
    path: 'wechats',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/wechats'));
      });
    },
    meta: {
      title: '导粉号',
      icon: 'weixin',
      affix: true
    },
    children: [{
      path: '/',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/wechats/config'));
        });
      },
      name: 'wxconfig'
    }, {
      path: 'group',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/wechats/wechatGroup'));
        });
      },
      name: 'wxgroup'
    }, {
      path: 'workwechat',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/wechats/workWechat'));
        });
      },
      name: 'workwechat'
    },
    // 导粉标签
    {
      path: 'fanstag',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/wechats/fansTag'));
        });
      },
      name: 'fanstag'
    }]
  }, {
    path: 'teacher',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/teacher/index'));
      });
    },
    name: 'teacher',
    meta: {
      title: '老师信息',
      icon: 'teacher',
      affix: true
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods'));
      });
    },
    meta: {
      title: '商城商品',
      icon: 'shangpin',
      affix: true
    },
    children: [{
      path: '/',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/goods/config'));
        });
      },
      name: 'goodsItem',
      meta: {
        title: '',
        affix: true
      }
    }, {
      path: 'group',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/goods/group'));
        });
      },
      name: 'goodsGroup',
      meta: {
        title: '',
        affix: true
      }
    }]
  },
  // {
  //     path: 'productdomain',
  //     component: () => import('@/views/productdomain/index'),
  //     name: 'productdomain',
  //     meta: {
  //         title: '产品入口域名库',
  //         icon: 'my-domain',
  //         affix: true
  //     }
  // },
  // {
  //     path: 'imgitemmanage',
  //     component: () => import('@/views/imgitemmanage/index'),
  //     name: 'imgitemmanage',
  //     meta: {
  //         title: '图文列表项管理',
  //         icon: 'my-list',
  //         affix: true
  //     }
  // },
  // {
  //     path: 'modulerouter',
  //     component: () => import('@/views/modulerouter/index'),
  //     name: 'modulerouter',
  //     meta: {
  //         title: '应用模块路由库',
  //         icon: 'my-router',
  //         affix: true
  //     }
  // },
  {
    path: 'backend',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/backend/index'));
      });
    },
    meta: {
      title: '后端资源配置',
      icon: 'backend',
      affix: true,
      noCache: true
    },
    children: [{
      path: '/',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/backend/tag'));
        });
      },
      name: 'backendTag',
      meta: {
        title: '',
        affix: true,
        noCache: true
      }
    }, {
      path: 'tfBackendTag',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/backend/tag'));
        });
      },
      name: 'tfBackendTag',
      meta: {
        title: '',
        affix: true,
        from: 'tf'
      }
    }, {
      path: 'qmBackendTag',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/backend/tag'));
        });
      },
      name: 'qmBackendTag',
      meta: {
        title: '',
        affix: true,
        from: 'qm'
      }
    }, {
      path: 'config',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/backend/config'));
        });
      },
      name: 'backendConfig',
      meta: {
        title: '',
        affix: true,
        noCache: true
      }
    }
    // {
    //     path: 'tfBackendConfig',
    //     hidden: true,
    //     component: () => import('@/views/backend/config'),
    //     name: 'tfBackendConfig',
    //     meta: {
    //         title: '',
    //         affix: true,
    //         noCache: true
    //     }
    // }
    ]
  }, {
    path: 'wechatapp',
    name: 'wechatapp',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/wechatapp/index'));
      });
    },
    meta: {
      title: '微信应用配置',
      icon: 'wechatapp',
      affix: true
    }
  }, {
    path: 'postback',
    name: 'postback',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/postback/index'));
      });
    },
    meta: {
      title: '投放回传数据配置',
      icon: 'my-pageconfig',
      affix: true
    }
  }]
},
// 测算推荐
{
  path: '/recommended',
  redirect: '/recommended/resource-niche',
  component: _layout.default,
  alwaysShow: true,
  permission: true,
  meta: {
    title: '测算推荐',
    icon: 'my-resource',
    affix: true
  },
  children: [{
    path: 'resource-niche',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recommended/resource-niche'));
      });
    },
    name: 'resource-niche',
    permission: true,
    meta: {
      title: '测算资源位',
      icon: 'form',
      affix: true
    }
  }, {
    path: 'collection',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recommended/collection'));
      });
    },
    name: 'collection',
    permission: true,
    meta: {
      title: '测算大全',
      icon: 'form',
      affix: true
    }
  }, {
    path: 'qiming-resource',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recommended/qiming-resource'));
      });
    },
    name: 'qiming-resource',
    permission: true,
    meta: {
      title: '起名测算资源位',
      icon: 'form',
      affix: true
    }
  }]
},
// 权限管理
{
  path: '/auth',
  redirect: '/auth/usermanage',
  component: _layout.default,
  alwaysShow: true,
  permission: true,
  meta: {
    title: '权限管理',
    icon: 'my-quanxianguanli',
    affix: true
  },
  children: [{
    path: 'usermanage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auth/userManage'));
      });
    },
    name: 'usermanage',
    permission: true,
    meta: {
      title: '用户管理',
      icon: 'form',
      affix: true
    }
  }, {
    path: 'rolemanage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auth/roleManage'));
      });
    },
    name: 'rolemanage',
    permission: true,
    meta: {
      title: '角色管理',
      icon: 'tree-table',
      affix: true
    }
  }, {
    path: 'roleedit/:ID?',
    name: 'roleedit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auth/roleEdit'));
      });
    },
    hidden: true,
    meta: {
      title: '角色编辑'
    }
  }, {
    path: 'useredit/:ID',
    name: 'useredit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auth/userEdit'));
      });
    },
    hidden: true,
    meta: {
      title: '用户编辑'
    }
  }, {
    path: 'permitmanage',
    name: 'permitmanage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auth/permitManage'));
      });
    },
    permission: true,
    meta: {
      title: '权限管理',
      icon: 'my-quanxian',
      affix: true
    }
  }, {
    path: 'operatelog',
    name: 'operatelog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auth/operateLog'));
      });
    },
    permission: true,
    meta: {
      title: '操作日志',
      icon: 'form',
      affix: true
    }
  }]
}, {
  path: '/login',
  name: 'login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/auth/login'));
    });
  },
  permission: true
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var router = new _vueRouter.default({
  mode: 'history',
  // require service support
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});

// 登录
router.beforeEach(function (to, from, next) {
  var accessToken = to.query.access_token;
  var hasToken = (0, _auth.getToken)();
  if (accessToken) {
    _store.default.dispatch('user/userLogin', {
      access_token: accessToken
    });
    next();
  } else if (hasToken) {
    _store.default.dispatch('user/getLoginUserInfo');
    next();
  } else {
    window.location.href = (0, _user.authLogin)();
  }
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;