"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addProductPattern = addProductPattern;
exports.deleteMultiProductPattern = deleteMultiProductPattern;
exports.deleteProductPattern = deleteProductPattern;
exports.editMultiProductPattern = editMultiProductPattern;
exports.editPattern = editPattern;
exports.editProductPattern = editProductPattern;
exports.editProductsPatternByOneButton = editProductsPatternByOneButton;
exports.editScene = editScene;
exports.getLiuLiangList = getLiuLiangList;
exports.getPatternList = getPatternList;
exports.getPatternMap = getPatternMap;
exports.getProductPatternList = getProductPatternList;
exports.getSceneList = getSceneList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 权重配置
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/cat_11627
 */

/**
 * 获取产品模型列表
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/91408
 */
function getProductPatternList(params) {
  return (0, _request.default)({
    url: '/resource/product/weighting/group/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取映射配置
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/91443
 */
function getPatternMap() {
  return (0, _request.default)({
    url: '/resource/product/weighting/map',
    method: 'get'
  });
}

/**
 * 新增产品模型配置
 * @param {object} data
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/91422
 */
function addProductPattern(data) {
  return (0, _request.default)({
    url: '/resource/product/weighting',
    method: 'post',
    data: data
  });
}

/**
 * 修改产品模型配置
 * @param {object} data
 * @param {number} id
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/91429
 */
function editProductPattern(id, data) {
  return (0, _request.default)({
    url: "/resource/product/weighting/".concat(id),
    method: 'post',
    data: data
  });
}

/**
 * 批量修改产品模型配置
 * @param {object} data
 * 详细参数可看接口文档 https://yapi.linghit.cn/project/755/interface/api/91457
 */
function editMultiProductPattern(data) {
  return (0, _request.default)({
    url: '/resource/product/multi/weighting',
    method: 'post',
    data: data
  });
}

/**
 * 一键配置产品模型
 * @param {object} data
 */
function editProductsPatternByOneButton(data) {
  return (0, _request.default)({
    url: "/resource/product/multi/weighting_class",
    method: 'post',
    data: data
  });
}

/**
 * 删除产品模型配置
 * @param {number} id
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/91436
 */
function deleteProductPattern(id) {
  return (0, _request.default)({
    url: "/resource/product/weighting/".concat(id),
    method: 'delete'
  });
}

/**
 * 批量删除产品模型配置
 * @param {object} data
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/91702
 */
function deleteMultiProductPattern(params) {
  return (0, _request.default)({
    url: '/resource/product/weighting',
    method: 'delete',
    params: params
  });
}

/**
 * 获取模型列表
 * @param {object} params
 * 详细参数可看接口文档 https://yapi.linghit.cn/project/755/interface/api/73726
 *
 */
function getPatternList(params) {
  return (0, _request.default)({
    url: '/resource/product/pattern',
    method: 'get',
    params: params
  });
}

/**
 * 修改模型
 * @param {string} id
 * @param {object} data
 * 详细参数可看接口文档 https://yapi.linghit.cn/project/755/interface/api/79571
 */
function editPattern(id, data) {
  return (0, _request.default)({
    url: "/resource/product/pattern/".concat(id),
    method: 'post',
    data: data
  });
}
function getSceneList(params) {
  return (0, _request.default)({
    url: '/resource/product/scene',
    method: 'get',
    params: params
  });
}
function editScene(id, data) {
  return (0, _request.default)({
    url: "/resource/product/scene/".concat(id),
    method: 'post',
    data: data
  });
}

// 流量分发类型
function getLiuLiangList() {
  return (0, _request.default)({
    url: '/resource/product/weighting/map',
    method: 'get'
  });
}