"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
var _recommended = require("@/api/recommended");
var _imageUpload = _interopRequireDefault(require("@/components/image-upload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ImageUpload: _imageUpload.default
  },
  props: {
    manageType: {
      type: String,
      default: ''
    },
    ruleForm: {
      url: '',
      name: '',
      desc: '',
      sequence: '',
      people_nums: '',
      comment_nums: '',
      favorable_rate: '',
      show_type: '',
      status: 'disable',
      id: -1
    },
    selectedPage: {
      type: String,
      default: ''
    },
    selectedResource: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      listData: {
        id: '',
        url: '',
        name: '',
        desc: '',
        sequence: '',
        peopleNums: 0,
        // 测过人数
        commentNums: 0,
        // 评论人数
        favorableRate: '98.8%',
        // 好评率
        showType: 'icon',
        // 展示方式 'banner','icon'
        status: 'enable'
      },
      iconUrl: {
        img_url: ''
      },
      bannerUrl: {
        img_url: ''
      },
      rules: {
        url: {
          required: true,
          message: '请输入测算链接',
          trigger: 'blur'
        },
        name: {
          required: true,
          message: '请输入测算标题',
          trigger: 'blur'
        },
        desc: {
          required: true,
          message: '请输入文案',
          trigger: 'blur'
        },
        sequence: {
          required: true,
          message: '请输入排序',
          trigger: 'blur'
        },
        peopleNums: {
          required: true,
          message: '请输入测过人数',
          trigger: 'blur'
        },
        commentNums: {
          required: true,
          message: '请输入评论人数',
          trigger: 'blur'
        },
        favorableRate: {
          required: true,
          message: '请输入好评率',
          trigger: 'blur'
        },
        showType: {
          required: true,
          message: '请选择展示方式',
          trigger: 'blur'
        },
        status: {
          required: false,
          message: '请选择上架状态',
          trigger: 'blur'
        }
      },
      showTypeData: [{
        value: 'icon',
        label: 'icon'
      }, {
        value: 'banner',
        label: 'banner'
      }],
      statusData: [{
        value: '启用',
        label: 'enable'
      }, {
        value: '禁用',
        label: 'disable'
      }]
    };
  },
  watch: {
    ruleForm: function ruleForm(value) {
      if (this.manageType === 'edit') {
        this.rules.status.required = true;
      }
      var id = value.id,
        url = value.url,
        name = value.name,
        desc = value.desc,
        sort = value.sort,
        people_nums = value.people_nums,
        comment_nums = value.comment_nums,
        favorable_rate = value.favorable_rate,
        show_type = value.show_type,
        status = value.status,
        icon = value.icon,
        banner = value.banner;
      var obj = {
        id: id,
        url: url,
        name: name,
        desc: desc,
        sequence: sort,
        peopleNums: people_nums,
        commentNums: comment_nums,
        favorableRate: favorable_rate,
        showType: show_type,
        status: status
      };
      this.listData = obj;
      this.iconUrl.img_url = icon;
      this.bannerUrl.img_url = banner;
    }
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      var iconUrlUpload = this.$refs.iconUrlUpload.validateData();
      var bannerUrlUpload = this.$refs.bannerUrlUpload.validateData();
      var imgFlag = iconUrlUpload && bannerUrlUpload;
      this.$refs[formName].validate(function (valid) {
        if (valid && imgFlag) {
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      var _this$listData = this.listData,
        id = _this$listData.id,
        name = _this$listData.name,
        desc = _this$listData.desc,
        url = _this$listData.url,
        sequence = _this$listData.sequence,
        peopleNums = _this$listData.peopleNums,
        commentNums = _this$listData.commentNums,
        favorableRate = _this$listData.favorableRate,
        showType = _this$listData.showType,
        status = _this$listData.status;
      var req = {
        name: name,
        desc: desc,
        url: url,
        sort: sequence,
        icon: this.iconUrl.img_url,
        banner: this.bannerUrl.img_url,
        people_nums: peopleNums,
        comment_nums: commentNums,
        favorable_rate: favorableRate.includes('%') ? favorableRate : favorableRate + '%',
        show_type: showType
      };
      var isAdd = this.manageType === 'add';
      if (isAdd) {
        req.position = this.selectedPage;
        req.product_id = this.selectedResource;
      } else {
        req.status = status;
      }
      var connector = isAdd ? (0, _recommended.addResource)(req) : (0, _recommended.editResource)(id, req);
      connector.then(function (_ref) {
        var code = _ref.code,
          msg = _ref.msg;
        if (code === 200) {
          _this2.$message({
            message: msg,
            type: 'success'
          });
          _this2.dialogVisible = false;
          _this2.$parent.getList(); // 更新父组件数据
        }
      }).catch();
    }
  }
};