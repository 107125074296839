"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addQmResource = addQmResource;
exports.addResource = addResource;
exports.addSiteResource = addSiteResource;
exports.editQmResource = editQmResource;
exports.editResource = editResource;
exports.editSiteResource = editSiteResource;
exports.getQmResourceList = getQmResourceList;
exports.getQmResourceMap = getQmResourceMap;
exports.getResourceList = getResourceList;
exports.getResourceMap = getResourceMap;
exports.getSiteResourceList = getSiteResourceList;
exports.getSiteResourceMap = getSiteResourceMap;
exports.resourceDetails = resourceDetails;
exports.siteResourceDetails = siteResourceDetails;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 资源位管理
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/cat_12372
 */

/**
 * 新在线-资源位-映射关系
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98674
 */
function getResourceMap() {
  return (0, _request.default)({
    url: '/resource/zxcs_resource_product/map',
    method: 'get'
  });
}

/**
 * 新在线-资源位-列表
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98674
 */
function getResourceList(params) {
  return (0, _request.default)({
    url: '/resource/zxcs_resource_product/list',
    method: 'get',
    params: params
  });
}

/**
 * 新在线-资源位-添加
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98681
 */
function addResource(params) {
  return (0, _request.default)({
    url: '/resource/zxcs_resource_product/add',
    method: 'POST',
    params: params
  });
}

/**
 * 新在线-资源位-详情
 * @param {number} id
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98688
 */
function resourceDetails(id, params) {
  return (0, _request.default)({
    url: "/resource/zxcs_resource_product/".concat(id),
    method: 'get',
    params: params
  });
}

/**
 * 新在线-资源位-编辑
 * @param {number} id
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98695
 */
function editResource(id, params) {
  return (0, _request.default)({
    url: "/resource/zxcs_resource_product/edit/".concat(id),
    method: 'POST',
    params: params
  });
}

/**
 * 非投放测算大全-映射关系
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98702
 */
function getSiteResourceMap() {
  return (0, _request.default)({
    url: '/resource/zxcs_site_product/map',
    method: 'get'
  });
}

/**
 * 非投放测算大全-列表
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98709
 */
function getSiteResourceList(params) {
  return (0, _request.default)({
    url: '/resource/zxcs_site_product/list',
    method: 'get',
    params: params
  });
}

/**
 * 非投放测算大全-添加
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98716
 */
function addSiteResource(params) {
  return (0, _request.default)({
    url: '/resource/zxcs_site_product/add',
    method: 'POST',
    params: params
  });
}

/**
 * 非投放测算大全-详情
 * @param {number} id
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98723
 */
function siteResourceDetails(id, params) {
  return (0, _request.default)({
    url: "/resource/zxcs_site_product/".concat(id),
    method: 'get',
    params: params
  });
}

/**
 * 非投放测算大全-编辑
 * @param {number} id
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/98730
 */
function editSiteResource(id, params) {
  return (0, _request.default)({
    url: "/resource/zxcs_site_product/edit/".concat(id),
    method: 'POST',
    params: params
  });
}

/**
 * 起名线-资源位-映射关系
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/102937
 */
function getQmResourceMap() {
  return (0, _request.default)({
    url: '/resource/qm_resource_product/map',
    method: 'get'
  });
}

/**
 * 起名线-资源位-列表
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/102944
 */
function getQmResourceList(params) {
  return (0, _request.default)({
    url: '/resource/qm_resource_product/list',
    method: 'get',
    params: params
  });
}

/**
 * 起名线-资源位-添加
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/102965
 */
function addQmResource(params) {
  return (0, _request.default)({
    url: '/resource/qm_resource_product/add',
    method: 'POST',
    params: params
  });
}

/**
 * 起名线-资源位-编辑
 * @param {number} id
 * @param {object} params
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/102972
 */
function editQmResource(id, params) {
  return (0, _request.default)({
    url: "/resource/qm_resource_product/edit/".concat(id),
    method: 'POST',
    params: params
  });
}