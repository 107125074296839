"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FANS_TYPES_MAP = exports.FANS_TYPES_LIST = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var FANS_TYPES_LIST = exports.FANS_TYPES_LIST = [{
  name: 'wechat',
  label: '个人微信',
  value: 1
}, {
  name: 'official_wechat',
  label: '公众号',
  value: 2
},
// 云晖需求，企业微信/获客链接 改为 渠道活码
{
  name: 'enterprise_wechat',
  label: '渠道活码',
  value: 3
}, {
  name: 'scene_live_code',
  label: '场景活码',
  value: 5
}];
var FANS_TYPES_MAP = exports.FANS_TYPES_MAP = new Map([[1, '个人微信'], [2, '公众号'], [3, '渠道活码'], [4, '渠道活码'], [5, '场景活码']]);