var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "element-loading-text": "新建页面中...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.5)",
            title: _vm.manageType == "add" ? "新增页面" : "修改页面信息",
            visible: _vm.dialogVisible,
            width: "50%",
            "show-close": false,
            "close-on-click-modal": true,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "配置模板", prop: "template_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled: _vm.manageType == "add" ? false : true,
                      },
                      on: { change: _vm.onTemplateIdChange },
                      model: {
                        value: _vm.formData.template_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "template_id", $$v)
                        },
                        expression: "formData.template_id",
                      },
                    },
                    _vm._l(_vm.templateList, function (item) {
                      return _c("el-option", {
                        key: item.template_id,
                        attrs: {
                          label: item.name,
                          value: item.template_id,
                          disabled: item.is_common === 1,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "页面名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      maxlength: "15",
                      "show-word-limit": "",
                      placeholder:
                        "请输入当前配置页面名称标识（如：麦玲玲结果页）",
                    },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tips" }, [
                _vm._v("注意：标识与公共模板标识一致才能继承公共配置"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "页面标识", prop: "flag" } },
                [
                  _c(
                    "div",
                    { staticClass: "flag-box" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder:
                              "请选择（注意：同一个应用的页面标识不能重复）",
                            disabled: _vm.manageType == "add" ? false : true,
                          },
                          model: {
                            value: _vm.formData.flag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "flag", $$v)
                            },
                            expression: "formData.flag",
                          },
                        },
                        _vm._l(_vm.flagOption, function (v, k) {
                          return _c("el-option", {
                            key: k,
                            attrs: { label: v.flag, value: v.flag },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "h5应用产品", prop: "app_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder:
                          "请选择h5应用产品（可输入产品名称/appid搜索）",
                        disabled: _vm.manageType !== "add",
                      },
                      model: {
                        value: _vm.formData.app_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "app_id", $$v)
                        },
                        expression: "formData.app_id",
                      },
                    },
                    _vm._l(_vm.products, function (v) {
                      return _c("el-option", {
                        key: v.id,
                        attrs: {
                          label: v.name + "（" + v.app_id + "）",
                          value: v.app_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "正式链接", prop: "production_url" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "当前配置页面正式链接",
                    },
                    model: {
                      value: _vm.formData.production_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "production_url", $$v)
                      },
                      expression: "formData.production_url",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "测试链接", prop: "sandbox_url" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "当前配置页面测试链接",
                    },
                    model: {
                      value: _vm.formData.sandbox_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sandbox_url", $$v)
                      },
                      expression: "formData.sandbox_url",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }