"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
var _recommended = require("@/api/recommended");
var _lodash = _interopRequireDefault(require("lodash"));
var _collectionManage = _interopRequireDefault(require("./components/collection-manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    CollectionManage: _collectionManage.default
  },
  data: function data() {
    return {
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      perPage: 15,
      totalNumber: 0,
      // 总数量
      loading: true,
      theCateId: [],
      selectedCateId: '',
      channel: '',
      manageType: '',
      chooseData: {}
    };
  },
  filters: {
    getName: function getName(val, theCateId) {
      var result = theCateId.find(function (v) {
        if (v.label === val) {
          return v;
        }
      });
      return result ? result.value : '';
    }
  },
  mounted: function mounted() {
    this.getMap();
  },
  methods: {
    getMap: function getMap() {
      var _this = this;
      (0, _recommended.getSiteResourceMap)().then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code === 200) {
          var resultData = data.data;
          var arr1 = [];
          resultData.forEach(function (v) {
            arr1.push({
              value: v.title,
              label: v.key
            });
          });
          _this.theCateId = arr1; // 页面数据
          _this.selectedCateId = arr1[0].label; // 默认选中的页面
          _this.getList();
        }
      }).catch(function () {
        _this.loading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      if (!this.selectedCateId) {
        this.$message.error('请选择页面');
        return;
      }
      this.loading = true;
      var params = {
        cate_id: this.selectedCateId,
        channel: this.channel,
        page: this.currentPage,
        per_page: this.perPage
      };
      (0, _recommended.getSiteResourceList)(params).then(function (_ref2) {
        var code = _ref2.code,
          data = _ref2.data;
        _this2.loading = false;
        if (code === 200) {
          var list = data.list,
            meta = data.meta;
          _this2.tableData = list;
          _this2.totalPage = meta.page.total_page;
          _this2.totalNumber = meta.page.total;
        }
      }).catch(function () {
        _this2.loading = false;
      });
    },
    onSearch: function onSearch() {
      this.getList();
    },
    onAdd: function onAdd() {
      this.$refs.managePop.dialogVisible = true;
      this.manageType = 'add';
      this.chooseData = {};
    },
    onModify: function onModify(val) {
      this.$refs.managePop.dialogVisible = true;
      this.manageType = 'edit';
      this.chooseData = _lodash.default.cloneDeep(val);
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.loading = true;
      this.getList();
    },
    onCopy: function onCopy() {
      this.$message({
        type: 'success',
        message: '复制成功!',
        duration: 1000
      });
    },
    onError: function onError() {
      this.$message.error('复制失败!');
    }
  }
};