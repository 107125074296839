var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "configPage",
      staticClass: "config-wrapper",
      attrs: {
        "element-loading-text": _vm.loadingText,
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.5)",
      },
    },
    [
      _c("header", { staticClass: "top-operate-module" }, [
        !_vm.isNewWindow
          ? _c(
              "div",
              { staticClass: "abort-edit", on: { click: _vm.onEdit } },
              [_vm._v("退出编辑")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "page-info" }, [
          _vm.type === "public"
            ? _c("div", { staticClass: "font-16" }, [
                _c("span", [_vm._v("模板名：")]),
                _vm._v("在线测算"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type !== "public"
            ? _c("div", { staticClass: "font-16" }, [
                _c("span", [_vm._v("应用名：")]),
                _vm._v(_vm._s(_vm.page_init_data.name) + "\n            "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "font-16",
              staticStyle: { "padding-right": "30px" },
            },
            [
              _c("span", [_vm._v("页面名称：")]),
              _vm._v(_vm._s(_vm.page_init_data.title) + "\n            "),
            ]
          ),
          _vm._v(" "),
          _vm.type !== "public"
            ? _c("div", { staticClass: "font-14" }, [
                _c("span", [_vm._v("APPID：")]),
                _vm._v(_vm._s(_vm.page_init_data.app_id) + "\n            "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "font-14" }, [
            _c("span", [_vm._v("页面标识：")]),
            _vm._v(_vm._s(_vm.page_init_data.flag)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "preview-btns" }, [
          _vm.type !== "public"
            ? _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "el-dropdown",
                    { on: { command: _vm.handleCommand } },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v("\n                        预览"),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: _vm.page_init_data.sandbox_url,
                                disabled: _vm.page_init_data.sandbox_url
                                  ? false
                                  : true,
                              },
                            },
                            [
                              _vm._v(
                                "\n                            测服链接：" +
                                  _vm._s(
                                    _vm.page_init_data.sandbox_url ||
                                      "还未配置链接，请先配置"
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: _vm.page_init_data.production_url,
                                disabled: _vm.page_init_data.production_url
                                  ? false
                                  : true,
                              },
                            },
                            [
                              _vm._v(
                                "\n                            线上链接：" +
                                  _vm._s(
                                    _vm.page_init_data.production_url ||
                                      "还未配置链接，请先配置"
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.onSave("dev")
                    },
                  },
                },
                [
                  _vm._v("\n                    保存"),
                  _c("span", { staticClass: "font-10" }, [
                    _vm._v("（测服环境）"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.onSave("pro")
                    },
                  },
                },
                [
                  _vm._v("\n                    同步"),
                  _c("span", { staticClass: "font-10" }, [
                    _vm._v("（线上环境）"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-last" },
            [
              _c(
                "el-dropdown",
                [
                  _c("div", { staticClass: "warn-icon" }, [
                    _c("span", {
                      class:
                        _vm.page_init_data.status === "dev"
                          ? "icon-red"
                          : "icon-green",
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          _vm._s(
                            _vm.page_init_data.status === "dev"
                              ? "当前配置还未同步线上"
                              : "当前配置已同步线上"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-decoration-module" },
        [
          _c("ModuleSidebar"),
          _vm._v(" "),
          _c(
            "div",
            { ref: "scroll", staticClass: "scroll-module" },
            [
              _c(
                "div",
                { staticClass: "module-jump-switch" },
                [
                  _c(
                    "el-tag",
                    { attrs: { type: "info" } },
                    [
                      _vm._v("组件资源位链接跳转\n                    "),
                      _c("el-switch", {
                        attrs: {
                          width: 31,
                          value: _vm.switchFlag == "on" ? true : false,
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        on: { change: _vm.switchChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.type !== "public" && _vm.showPublicEntry()
                ? _c(
                    "div",
                    { staticClass: "public-entrance" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-16",
                          on: {
                            click: function ($event) {
                              return _vm.toPublicConfig(_vm.page_init_data.flag)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    当前页面的公共配置\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("Tips"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("ModulePage"),
            ],
            1
          ),
          _vm._v(" "),
          _c("ModuleConfig", { ref: "moduleConfig" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }