"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _yunying = require("@/api/yunying");
var _validate = require("@/utils/validate");
var _vuex = require("vuex");
var _addFlag = _interopRequireDefault(require("./add-flag"));
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    AddFlag: _addFlag.default
  },
  props: {
    manageType: {},
    formData: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          flag: '',
          app_id: '',
          production_url: '',
          sandbox_url: ''
        };
      }
    }
  },
  data: function data() {
    var validateUrl = function validateUrl(rule, value, callback) {
      if (value && !(0, _validate.isCorrectUrl)(value)) {
        callback(new Error('链接格式不正确'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      loading2: false,
      dialogVisible: false,
      rules: {
        title: {
          required: true,
          message: '请输入页面名称',
          trigger: 'blur'
        },
        flag: {
          required: true,
          message: '请选择页面标识',
          trigger: 'change'
        },
        app_id: {
          required: true,
          message: '请选择h5应用产品',
          trigger: 'change'
        },
        production_url: {
          required: false,
          validator: validateUrl,
          trigger: 'blur'
        },
        sandbox_url: {
          required: false,
          validator: validateUrl,
          trigger: 'blur'
        }
      },
      flagOption: [],
      // 页面标识
      productOption: [],
      // 产品列表
      appTypes: [{
        value: 'zxcs',
        label: '测算产品'
      }, {
        value: 'qm',
        label: '起名产品'
      }],
      appType: ''
    };
  },
  mounted: function mounted() {
    this.getPageFlag();
    this.getAllProducts();
    this.getAllQmProducts();
  },
  computed: (0, _vuex.mapState)({
    products: function products(state) {
      var originProducts = [];
      switch (this.appType) {
        case 'zxcs':
          originProducts = _lodash.default.cloneDeep(state.common.all_products);
          break;
        case 'qm':
          originProducts = _lodash.default.cloneDeep(state.common.all_qm_products);
      }
      var products = originProducts.filter(function (item) {
        return !!item.app_id;
      });
      return products;
    }
  }),
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    getAllProducts: 'common/getAllProducts',
    getAllQmProducts: 'common/getAllQmProducts'
  })), {}, {
    // 获取标识列表
    getPageFlag: function getPageFlag() {
      var _this = this;
      // module=page页面配置，module=fans导粉配置
      var name = this.$route.name;
      (0, _yunying.getPageFlag)().then(function (_ref) {
        var data = _ref.data;
        _this.flagOption = data.filter(function (item) {
          return item.module === name;
        });
      });
    },
    // 新增标识
    addFlagHandle: function addFlagHandle(name) {
      var _this2 = this;
      (0, _yunying.addPageFlag)({
        flag: name
      }).then(function (_ref2) {
        var data = _ref2.data;
        if (data) {
          _this2.$message({
            message: '添加成功',
            type: 'success',
            duration: 1000
          });
          _this2.$refs.addFlag.dialogVisible = false;
          // 新增以后更新列表
          _this2.getPageFlag();
        }
      });
    },
    // 新增标识弹窗
    onAddFlagPop: function onAddFlagPop() {
      this.$refs.addFlag.dialogVisible = true;
    },
    // 提交数据
    onSubmit: function onSubmit() {
      var _this3 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var formData = _this3.formData,
            manageType = _this3.manageType;
          if (manageType === 'add') {
            _this3.loading = true;
            var app_id = formData.app_id;
            var selectApp = _this3.products.find(function (item) {
              return item.app_id === app_id;
            });
            _this3.createPageHandle(_objectSpread(_objectSpread({}, formData), {}, {
              name: selectApp.name
            }));
          } else {
            _this3.updatePageInfo(formData);
          }
        }
      });
    },
    // 新建页面
    createPageHandle: function createPageHandle(formData) {
      var _this4 = this;
      (0, _yunying.createPage)(formData).then(function (_ref3) {
        var data = _ref3.data;
        _this4.loading = false;
        if (data) {
          _this4.$alert('页面创建成功, 是否立马前往进行资源配置?', '提示', {
            confirmButtonText: '确定',
            callback: function callback(action) {
              _this4.dialogVisible = false;
              if (action == 'confirm') {
                _this4.$emit('upDateList');
                var name = _this4.$route.name;
                _this4.$router.push({
                  name: 'config',
                  query: {
                    page_id: data,
                    flag: formData.flag,
                    modules: name
                  }
                });
              } else {
                _this4.$emit('upDateList');
              }
            }
          });
        }
      }).catch(function (_ref4) {
        var err = _ref4.err;
        _this4.loading = false;
        console.log(err);
      });
    },
    // 修改页面
    updatePageInfo: function updatePageInfo(formData) {
      var _this5 = this;
      var params = {
        page_id: formData.id,
        title: formData.title,
        production_url: formData.production_url,
        sandbox_url: formData.sandbox_url
      };
      (0, _yunying.updatePageInfo)(params).then(function (_ref5) {
        var data = _ref5.data;
        _this5.$message({
          message: '修改成功',
          type: 'success'
        });
        _this5.dialogVisible = false;
        // 更新数据
        _this5.$emit('upDateList');
      });
    },
    selectType: function selectType() {
      this.formData.app_id = '';
    }
  })
};