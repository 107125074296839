var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { "margin-bottom": "30px" },
          on: { input: _vm.onTemplateIdChange },
          model: {
            value: _vm.templateId,
            callback: function ($$v) {
              _vm.templateId = $$v
            },
            expression: "templateId",
          },
        },
        _vm._l(_vm.templateList, function (v, k) {
          return _c(
            "el-radio-button",
            { key: k, attrs: { label: v.template_id } },
            [_vm._v("\n            " + _vm._s(v.name) + "\n        ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      !_vm.isCommonTemplate
        ? _c(
            "el-form",
            {
              attrs: { inline: "" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-with-select width-300",
                      attrs: {
                        placeholder: "输入应用名称/APPID",
                        clearable: "",
                      },
                      on: { change: _vm.onSearch },
                      model: {
                        value: _vm.searchInput,
                        callback: function ($$v) {
                          _vm.searchInput = $$v
                        },
                        expression: "searchInput",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.onAdd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        [
          _vm.isCommonTemplate
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.commonTableData,
                    border: "",
                    stripe: "",
                    "header-cell-style": _vm.getHeaderStyle(2),
                  },
                  on: { "expand-change": _vm.onExpandChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-card",
                                {
                                  staticClass: "config-card",
                                  attrs: { shadow: "hover" },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.configMap.get(
                                          scope.row.page_id
                                        ),
                                        "header-cell-style":
                                          _vm.getHeaderStyle(3),
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "title",
                                          label: "内容配置",
                                          align: "center",
                                          width: "100",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "channel_item",
                                          label: "渠道",
                                          align: "center",
                                          width: "200",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return _vm._l(
                                                  scope.row.channel_item,
                                                  function (v, k) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: k,
                                                        class:
                                                          _vm.colorList[
                                                            k %
                                                              _vm.colorList
                                                                .length
                                                          ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(v) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "channel_group",
                                          label: "正则渠道",
                                          align: "center",
                                          width: "200",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return _vm._l(
                                                  scope.row.channel_group,
                                                  function (v, k) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: k,
                                                        class:
                                                          _vm.colorList[
                                                            k %
                                                              _vm.colorList
                                                                .length
                                                          ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(v) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "wx_group",
                                          label: "微信分组",
                                          align: "center",
                                          "min-width": "200",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "teacher_group",
                                          label: "老师分组",
                                          align: "center",
                                          "min-width": "200",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "extend",
                                          label: "扩展",
                                          "min-width": "200",
                                          align: "center",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      909226781
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "title",
                      label: "页面名称",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "flag", label: "页面标识" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "last_at",
                      label: "上次修改时间",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "updated_at",
                      label: "上次同步时间",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onConfig(scope.row, "public")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        资源配置\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4033246240
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        [
          !_vm.isCommonTemplate
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    stripe: "",
                    "header-cell-style": _vm.getHeaderStyle(1),
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "98%",
                                    margin: "0 auto",
                                  },
                                  attrs: {
                                    data: scope.row.children,
                                    border: "",
                                    "header-cell-style": _vm.getHeaderStyle(2),
                                  },
                                  on: { "expand-change": _vm.onExpandChange },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "expand" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-card",
                                                {
                                                  staticClass: "config-card",
                                                  attrs: { shadow: "hover" },
                                                },
                                                [
                                                  _c(
                                                    "el-table",
                                                    {
                                                      attrs: {
                                                        data: _vm.configMap.get(
                                                          scope.row.page_id
                                                        ),
                                                        "header-cell-style":
                                                          _vm.getHeaderStyle(3),
                                                      },
                                                    },
                                                    [
                                                      _c("el-table-column", {
                                                        attrs: {
                                                          prop: "title",
                                                          label: "内容配置",
                                                          align: "center",
                                                          width: "100",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-table-column", {
                                                        attrs: {
                                                          prop: "channel_item",
                                                          label: "渠道",
                                                          align: "center",
                                                          width: "200",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                scope
                                                              ) {
                                                                return _vm._l(
                                                                  scope.row
                                                                    .channel_item,
                                                                  function (
                                                                    v,
                                                                    k
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: k,
                                                                        class:
                                                                          _vm
                                                                            .colorList[
                                                                            k %
                                                                              _vm
                                                                                .colorList
                                                                                .length
                                                                          ],
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                    " +
                                                                            _vm._s(
                                                                              v
                                                                            ) +
                                                                            "\n                                                "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-table-column", {
                                                        attrs: {
                                                          prop: "channel_group",
                                                          label: "正则渠道",
                                                          align: "center",
                                                          width: "200",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                scope
                                                              ) {
                                                                return _vm._l(
                                                                  scope.row
                                                                    .channel_group,
                                                                  function (
                                                                    v,
                                                                    k
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: k,
                                                                        class:
                                                                          _vm
                                                                            .colorList[
                                                                            k %
                                                                              _vm
                                                                                .colorList
                                                                                .length
                                                                          ],
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                    " +
                                                                            _vm._s(
                                                                              v
                                                                            ) +
                                                                            "\n                                                "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-table-column", {
                                                        attrs: {
                                                          prop: "wx_group",
                                                          label: "微信分组",
                                                          align: "center",
                                                          "min-width": "200",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-table-column", {
                                                        attrs: {
                                                          prop: "teacher_group",
                                                          label: "老师分组",
                                                          align: "center",
                                                          "min-width": "200",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-table-column", {
                                                        attrs: {
                                                          prop: "extend",
                                                          label: "扩展",
                                                          "min-width": "200",
                                                          align: "center",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "title",
                                      label: "页面名称",
                                      width: "180",
                                      "header-align": "center",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "flag",
                                      label: "页面标识",
                                      "min-width": "120",
                                      "header-align": "center",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "sandbox_url",
                                      label: "配置页测服链接",
                                      "min-width": "120",
                                      "header-align": "center",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.sandbox_url
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-link",
                                                        {
                                                          attrs: {
                                                            type: "info",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.checkLink(
                                                                scope.row
                                                                  .sandbox_url
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        查看\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-link",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "clipboard",
                                                              rawName:
                                                                "v-clipboard:copy",
                                                              value:
                                                                scope.row
                                                                  .sandbox_url,
                                                              expression:
                                                                "scope.row.sandbox_url",
                                                              arg: "copy",
                                                            },
                                                            {
                                                              name: "clipboard",
                                                              rawName:
                                                                "v-clipboard:success",
                                                              value: _vm.onCopy,
                                                              expression:
                                                                "onCopy",
                                                              arg: "success",
                                                            },
                                                            {
                                                              name: "clipboard",
                                                              rawName:
                                                                "v-clipboard:error",
                                                              value:
                                                                _vm.onError,
                                                              expression:
                                                                "onError",
                                                              arg: "error",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "info",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        复制\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tips-nolink",
                                                    },
                                                    [_vm._v("还未配置")]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "production_url",
                                      label: "配置页线上链接",
                                      "min-width": "120",
                                      "header-align": "center",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.production_url
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-link",
                                                        {
                                                          attrs: {
                                                            type: "info",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.checkLink(
                                                                scope.row
                                                                  .production_url
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        查看\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-link",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "clipboard",
                                                              rawName:
                                                                "v-clipboard:copy",
                                                              value:
                                                                scope.row
                                                                  .production_url,
                                                              expression:
                                                                "scope.row.production_url",
                                                              arg: "copy",
                                                            },
                                                            {
                                                              name: "clipboard",
                                                              rawName:
                                                                "v-clipboard:success",
                                                              value: _vm.onCopy,
                                                              expression:
                                                                "onCopy",
                                                              arg: "success",
                                                            },
                                                            {
                                                              name: "clipboard",
                                                              rawName:
                                                                "v-clipboard:error",
                                                              value:
                                                                _vm.onError,
                                                              expression:
                                                                "onError",
                                                              arg: "error",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "info",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        复制\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tips-nolink",
                                                    },
                                                    [_vm._v("还未配置")]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "created_at",
                                      label: "创建时间",
                                      width: "160",
                                      "header-align": "center",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "updated_at",
                                      label: "上次同步时间",
                                      width: "160",
                                      "header-align": "center",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "cancel",
                                      label: "操作",
                                      width: "200",
                                      "header-align": "center",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onModify(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    修改\n                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onConfig(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    资源配置\n                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("el-button", {
                                                attrs: {
                                                  type: "text",
                                                  icon: "el-icon-delete",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onDelete(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      48342463
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "应用名称",
                      "min-width": "100",
                      "header-align": "center",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "app_id",
                      label: "APP ID",
                      "min-width": "120",
                      "header-align": "center",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sandbox_url",
                      label: "应用测服链接",
                      "min-width": "130",
                      "header-align": "center",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.sandbox_url
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "info" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkLink(
                                                scope.row.sandbox_url
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            查看\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: scope.row.sandbox_url,
                                              expression:
                                                "scope.row.sandbox_url",
                                              arg: "copy",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:success",
                                              value: _vm.onCopy,
                                              expression: "onCopy",
                                              arg: "success",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:error",
                                              value: _vm.onError,
                                              expression: "onError",
                                              arg: "error",
                                            },
                                          ],
                                          attrs: { type: "info" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            复制\n                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", { staticClass: "tips-nolink" }, [
                                    _vm._v("还未配置"),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      218147893
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "production_url",
                      label: "应用正式链接",
                      "min-width": "130",
                      "header-align": "center",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.production_url
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "info" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkLink(
                                                scope.row.production_url
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            查看\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: scope.row.production_url,
                                              expression:
                                                "scope.row.production_url",
                                              arg: "copy",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:success",
                                              value: _vm.onCopy,
                                              expression: "onCopy",
                                              arg: "success",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:error",
                                              value: _vm.onError,
                                              expression: "onError",
                                              arg: "error",
                                            },
                                          ],
                                          attrs: { type: "info" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            复制\n                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", { staticClass: "tips-nolink" }, [
                                    _vm._v("还未配置"),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2055857013
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tablePage.total_page > 0 && !_vm.isCommonTemplate
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.tablePage.current,
                  "page-size": _vm.tablePage.per_page,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.tablePage.total_page,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Manage", {
        ref: "managePop",
        attrs: {
          manageType: _vm.manageType,
          formData: _vm.chooseData,
          templateList: _vm.templateList,
        },
        on: {
          "update:formData": function ($event) {
            _vm.chooseData = $event
          },
          "update:form-data": function ($event) {
            _vm.chooseData = $event
          },
          upDateList: _vm.upDateList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }