"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _slicedToArray2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _pattern = require("@/api/pattern");
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          fan_rate: 0,
          // 导粉率
          guideTime: []
        };
      }
    },
    issues: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    patterns: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var _this = this;
    var validateMax = function validateMax(rule, value, callback) {
      if (_this.showMax && (typeof value !== 'number' || value <= 0)) {
        callback(new Error('请输入订单数'));
      } else {
        callback();
      }
    };
    var validatePid = function validatePid(rule, value, callback) {
      if (_this.showMax && !value) {
        callback(new Error('请输入选择模型'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      rules: {
        fan_rate: {
          required: true,
          message: '请填写导粉率',
          trigger: 'blur'
        },
        o_sum: {
          validator: validateMax,
          trigger: 'blur'
        },
        next_pid: {
          validator: validatePid,
          trigger: 'blur'
        }
      },
      canClick: true,
      // 是否可以点击提交按钮
      showMax: false,
      ageRotioOptions: [{
        value: '50',
        label: '50后'
      }, {
        value: '60',
        label: '60后'
      }, {
        value: '70',
        label: '70后'
      }, {
        value: '80',
        label: '80后'
      }, {
        value: '90',
        label: '90后'
      }, {
        value: '00',
        label: '00后'
      }],
      ageRotio: [],
      ageRotioList: []
    };
  },
  watch: {
    ruleForm: function ruleForm(_ref) {
      var o_sum = _ref.o_sum,
        basket = _ref.basket;
      this.showMax = o_sum > 0;
      if (basket.age_tag) {
        var ageList = [];
        var ageRotio = [];
        for (var _i = 0, _Object$keys = Object.keys(basket.age_tag); _i < _Object$keys.length; _i++) {
          var k = _Object$keys[_i];
          ageList.push(_objectSpread({
            age: k
          }, basket.age_tag[k]));
          ageRotio.push(k);
        }
        this.ageRotio = ageRotio;
        this.ageRotioList = ageList;
      }
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this3 = this;
      if (!this.canClick) {
        this.$message({
          message: '请勿重复提交！',
          type: 'warning'
        });
        return;
      }
      var newGuideTimeList = [];
      var userAges = [];
      var _this$ruleForm = this.ruleForm,
        id = _this$ruleForm.id,
        fan_rate = _this$ruleForm.fan_rate,
        guideTime = _this$ruleForm.guideTime,
        gender = _this$ruleForm.gender,
        ages = _this$ruleForm.ages,
        o_sum = _this$ruleForm.o_sum,
        next_pid = _this$ruleForm.next_pid,
        issue = _this$ruleForm.issue;
      var age_tag = {};
      var next_pid_value = '';
      var _iterator = _createForOfIteratorHelper(this.patterns),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var pattern = _step.value;
          if (pattern.value === next_pid) {
            next_pid_value = pattern.label;
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      var _iterator2 = _createForOfIteratorHelper(guideTime),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var item = _step2.value;
          var _item = (0, _slicedToArray2.default)(item, 2),
            startDate = _item[0],
            endDate = _item[1];
          if (!startDate || !endDate) {
            this.$message({
              message: '请选择时间！',
              type: 'warning'
            });
            return;
          }
          if (startDate.getTime() === endDate.getTime()) {
            this.$message({
              message: '结束时间不能与开始时间相同！',
              type: 'warning'
            });
            return;
          }
          var newItem = this.getFormatTime(startDate, endDate);
          newGuideTimeList.push(newItem);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      var _iterator3 = _createForOfIteratorHelper(ages),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var _item2 = _step3.value;
          var _item3 = (0, _slicedToArray2.default)(_item2, 2),
            min = _item3[0],
            max = _item3[1];
          if (min === max) {
            this.$message({
              message: '最小年龄与最大年龄不能相同',
              type: 'warning'
            });
            return;
          }
          userAges.push("".concat(this.addPrefixZero(min), "-").concat(this.addPrefixZero(max)));
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      var _iterator4 = _createForOfIteratorHelper(this.ageRotioList),
        _step4;
      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var _step4$value = _step4.value,
            age = _step4$value.age,
            ratios = _step4$value.ratios,
            scene_id = _step4$value.scene_id;
          age_tag[age] = {
            ratios: ratios,
            scene_id: scene_id
          };
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
      var params = {
        fan_rate: fan_rate,
        basket: {
          gender: gender,
          next_pid_value: next_pid_value,
          guide_time: newGuideTimeList.length > 0 ? newGuideTimeList : '',
          // 兼容删除的情况
          age: userAges.length > 0 ? userAges : '',
          o_sum: o_sum > 0 ? o_sum : '',
          next_pid: next_pid ? next_pid : '',
          age_tag: age_tag
        },
        issue: issue ? issue : ''
      };
      (0, _pattern.editPattern)(id, params).then(function (_ref2) {
        var code = _ref2.code;
        if (code === 200) {
          _this3.$message({
            message: '修改成功',
            type: 'success'
          });
          _this3.dialogVisible = false;
          _this3.$parent.getList(); // 更新父组件数据
          _this3.canClick = true;
        }
      }).catch(function (err) {
        _this3.canClick = true;
      });
    },
    addPrefixZero: function addPrefixZero(number) {
      return number >= 10 ? number : '0' + number;
    },
    getFormatTime: function getFormatTime(startDate, endDate) {
      var sHour = this.addPrefixZero(startDate.getHours());
      var sMinute = this.addPrefixZero(startDate.getMinutes());
      var eHour = this.addPrefixZero(endDate.getHours());
      var eMinute = this.addPrefixZero(endDate.getMinutes());
      var time = "".concat(sHour, ":").concat(sMinute, "-").concat(eHour, ":").concat(eMinute);
      return time;
    },
    addFormItem: function addFormItem(key) {
      switch (key) {
        case 'guideTime':
          this.ruleForm[key].push([new Date(2024, 0, 1, 0, 0), new Date(2024, 0, 1, 23, 59)]);
          break;
        case 'ages':
          this.ruleForm[key].push([20, 50]);
          break;
        case 'max':
          this.$set(this.ruleForm, 'o_sum', 200);
          break;
      }
    },
    delFormItem: function delFormItem(key, index) {
      switch (key) {
        case 'guideTime':
        case 'ages':
          this.ruleForm[key].splice(index, 1);
          break;
        case 'max':
          this.$set(this.ruleForm, 'o_sum', undefined);
          this.$set(this.ruleForm, 'next_pid', undefined);
          this.showMax = false;
          break;
      }
    },
    ageRotioChange: function ageRotioChange(vals) {
      var _this4 = this;
      var lists = vals.map(function (val) {
        var item = _this4.ageRotioList.find(function (_ref3) {
          var age = _ref3.age;
          return age === val;
        });
        if (item) {
          return item;
        }
        return {
          age: val,
          scene_id: '',
          ratios: {
            app: 50,
            qiwei: 50
          }
        };
      });
      this.ageRotioList = lists;
    },
    validateAgeTag: function validateAgeTag(rule, value, callback) {
      var _rule$data = rule.data,
        scene_id = _rule$data.scene_id,
        ratios = _rule$data.ratios;
      if (parseInt(ratios.app) + parseInt(ratios.qiwei) !== 100) {
        callback(new Error('app和企微占比之和必须为100'));
      }
      if (!scene_id) {
        callback(new Error('场景码不能为空'));
      }
      callback();
    }
  }
};