"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wechatapp = require("@/api/wechatapp");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    manageType: {
      type: String,
      default: ''
    },
    typeOptions: {
      type: Array,
      default: []
    },
    statusOptions: {
      type: Array,
      default: []
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          // 应用名称
          app_id: '',
          // 应用app_id
          origin_id: '',
          // 应用 origin id
          secret: '',
          // 应用密钥
          type: '',
          // 类别 1 公号 2小程序
          flag: '',
          // 分组标签
          status: '',
          // 状态 0无效 1有效  2备用
          program_alias: '',
          // 别称
          mark: '',
          // 备注
          stop_time: ''
        };
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      rules: {
        title: {
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        },
        app_id: {
          required: true,
          message: '请输入APP_ID',
          trigger: 'blur'
        },
        origin_id: {
          required: false,
          message: '请输入原始ID',
          trigger: 'blur'
        },
        secret: {
          required: false,
          message: '请输入密钥',
          trigger: 'blur'
        },
        type: {
          required: true,
          message: '请选择类型',
          trigger: 'blur'
        },
        flag: {
          required: false,
          message: '请输入分组标签',
          trigger: 'blur'
        },
        status: {
          required: true,
          message: '请选择状态',
          trigger: 'blur'
        },
        program_alias: {
          required: true,
          message: '请输入别称',
          trigger: 'blur'
        },
        mark: {
          required: false,
          message: '请输入备注',
          trigger: 'blur'
        },
        stop_time: {
          required: false,
          message: '请选择日期',
          trigger: 'blur'
        }
      }
    };
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      var _this$ruleForm = this.ruleForm,
        id = _this$ruleForm.id,
        title = _this$ruleForm.title,
        app_id = _this$ruleForm.app_id,
        origin_id = _this$ruleForm.origin_id,
        secret = _this$ruleForm.secret,
        type = _this$ruleForm.type,
        flag = _this$ruleForm.flag,
        status = _this$ruleForm.status,
        program_alias = _this$ruleForm.program_alias,
        mark = _this$ruleForm.mark,
        stop_time = _this$ruleForm.stop_time;
      var params = {
        title: title,
        app_id: app_id,
        type: type,
        status: status,
        program_alias: program_alias,
        mark: mark || ' ',
        //  接口要求无值需传空格，无删
        origin_id: origin_id || '',
        secret: secret || '',
        flag: flag || '',
        stop_time: stop_time || ''
      };
      if (this.manageType === 'add') {
        (0, _wechatapp.addWechatAppConfig)(params).then(function (_ref) {
          var code = _ref.code;
          if (code === 200) {
            _this2.$message({
              message: '添加成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      } else {
        (0, _wechatapp.editWechatAppConfig)(id, params).then(function (_ref2) {
          var code = _ref2.code;
          if (code === 200) {
            _this2.$message({
              message: '修改成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      }
    }
  }
};