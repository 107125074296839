var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: this.manageType === "add" ? "新增" : "修改",
        visible: _vm.dialogVisible,
        width: "60%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.listData,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "链接", prop: "url" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入链接（*新在线的测算不带域名）" },
                model: {
                  value: _vm.listData.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.listData, "url", $$v)
                  },
                  expression: "listData.url",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.listData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listData, "name", $$v)
                  },
                  expression: "listData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "文案", prop: "desc" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入文案" },
                model: {
                  value: _vm.listData.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.listData, "desc", $$v)
                  },
                  expression: "listData.desc",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sequence" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入排序" },
                model: {
                  value: _vm.listData.sequence,
                  callback: function ($$v) {
                    _vm.$set(_vm.listData, "sequence", $$v)
                  },
                  expression: "listData.sequence",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "测评人数", prop: "people" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入测评人数" },
                model: {
                  value: _vm.listData.people,
                  callback: function ($$v) {
                    _vm.$set(_vm.listData, "people", $$v)
                  },
                  expression: "listData.people",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.manageType === "edit"
            ? _c(
                "el-form-item",
                { attrs: { label: "上架状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-200",
                      attrs: { filterable: "", placeholder: "上架状态" },
                      model: {
                        value: _vm.listData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.listData, "status", $$v)
                        },
                        expression: "listData.status",
                      },
                    },
                    _vm._l(_vm.statusData, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.value, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("ImageUpload", {
            ref: "iconUrlUpload",
            attrs: {
              groupFlag: false,
              ruleForm: _vm.iconUrl,
              labelTitle: "icon",
              labelWidth: "110px",
            },
            on: {
              "update:ruleForm": function ($event) {
                _vm.iconUrl = $event
              },
              "update:rule-form": function ($event) {
                _vm.iconUrl = $event
              },
            },
          }),
          _vm._v(" "),
          _c("ImageUpload", {
            ref: "bannerUrlUpload",
            attrs: {
              groupFlag: false,
              ruleForm: _vm.bannerUrl,
              labelTitle: "banner",
              labelWidth: "110px",
            },
            on: {
              "update:ruleForm": function ($event) {
                _vm.bannerUrl = $event
              },
              "update:rule-form": function ($event) {
                _vm.bannerUrl = $event
              },
            },
          }),
          _vm._v(" "),
          _c("ImageUpload", {
            ref: "bannerLongUrlUpload",
            attrs: {
              groupFlag: false,
              ruleForm: _vm.bannerLongUrl,
              labelTitle: "banner_long",
              labelWidth: "110px",
            },
            on: {
              "update:ruleForm": function ($event) {
                _vm.bannerLongUrl = $event
              },
              "update:rule-form": function ($event) {
                _vm.bannerLongUrl = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }