"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCommonTemplateData = getCommonTemplateData;
exports.getTemplateData = getTemplateData;
exports.getTemplateFlag = getTemplateFlag;
exports.getTemplateList = getTemplateList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 导粉配置
 * 接口文档：https://yapi.linghit.cn/project/755/interface/api/cat_12872
 */

/** 获取模板列表 */
function getTemplateList() {
  return (0, _request.default)({
    url: '/resource/web/page/template',
    method: 'get'
  });
}

/** 获取公共模板数据 */
function getCommonTemplateData(templateId) {
  return (0, _request.default)({
    url: "/resource/web/page/template/".concat(templateId),
    method: 'get'
  });
}

/** 获取独立模板数据 */
function getTemplateData(params) {
  return (0, _request.default)({
    url: '/resource/web/page/template_extend',
    method: 'get',
    params: params
  });
}

/** 获取模板flag */
function getTemplateFlag(templateId) {
  return (0, _request.default)({
    url: "/resource/web/page/template_flag/".concat(templateId),
    method: 'get'
  });
}