var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改",
        visible: _vm.dialogVisible,
        width: "690px",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-position": "left",
            "label-width": "100px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模型名称", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "导粉率", prop: "fan_rate" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, max: 100, precision: 0 },
                model: {
                  value: _vm.ruleForm.fan_rate,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "fan_rate", $$v)
                  },
                  expression: "ruleForm.fan_rate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "流量分发", prop: "issue" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.issue,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "issue", $$v)
                    },
                    expression: "ruleForm.issue",
                  },
                },
                _vm._l(_vm.issues, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "强导设置" } },
            [
              _c(
                "el-card",
                {
                  attrs: {
                    "body-style": "background: #f7f8fa",
                    shadow: "never",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "fans-manage-item" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.addFormItem("guideTime")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        添加强导时间\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.ruleForm.guideTime, function (v, k) {
                    return _c(
                      "el-form-item",
                      { key: "time" + k, staticClass: "fans-manage-item" },
                      [
                        _c("el-time-picker", {
                          attrs: {
                            "is-range": "",
                            "range-separator": "至",
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                            placeholder: "选择时间范围",
                            format: "HH:mm",
                          },
                          model: {
                            value: _vm.ruleForm.guideTime[k],
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm.guideTime, k, $$v)
                            },
                            expression: "ruleForm.guideTime[k]",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delFormItem("guideTime", k)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.ruleForm.guideTime && _vm.ruleForm.guideTime.length > 0
                    ? _c(
                        "el-form-item",
                        { staticClass: "fans-manage-item" },
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _vm._v("条件外，订单去到"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        multiple: "",
                                        "collapse-tags": "",
                                        placeholder: "请选择",
                                        width: "100px",
                                      },
                                      on: {
                                        change: _vm.outOfCurrentTimeRatioChange,
                                      },
                                      model: {
                                        value: _vm.outOfCurrentTimeRatio,
                                        callback: function ($$v) {
                                          _vm.outOfCurrentTimeRatio = $$v
                                        },
                                        expression: "outOfCurrentTimeRatio",
                                      },
                                    },
                                    _vm._l(_vm.patterns, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.guideTime && _vm.ruleForm.guideTime.length > 0
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "fans-manage-range",
                          attrs: { prop: "outOfCurrentTimeList" },
                        },
                        _vm._l(_vm.outOfCurrentTimeList, function (v) {
                          return _c(
                            "el-form-item",
                            {
                              key: v.id + "timer",
                              staticClass: "fans-manage-item",
                            },
                            [
                              _c(
                                "el-col",
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(v.value) +
                                      "，占比：\n                            "
                                  ),
                                  _c("el-input", {
                                    staticClass: "fans-manage-order",
                                    staticStyle: { width: "70px" },
                                    model: {
                                      value: v.ratios,
                                      callback: function ($$v) {
                                        _vm.$set(v, "ratios", _vm._n($$v))
                                      },
                                      expression: "v.ratios",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "fans-manage-item" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.addFormItem("ages")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        添加强导年龄\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.ruleForm.ages, function (v, k) {
                    return _c(
                      "el-form-item",
                      { key: "age" + k, staticClass: "fans-manage-range" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 14 } },
                          [
                            _c("el-slider", {
                              attrs: {
                                range: "",
                                min: 1,
                                max: 100,
                                marks: {
                                  1: "1",
                                  25: "25",
                                  50: "50",
                                  75: "75",
                                  100: "100",
                                },
                              },
                              model: {
                                value: _vm.ruleForm.ages[k],
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm.ages, k, $$v)
                                },
                                expression: "ruleForm.ages[k]",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 5, offset: 1 } }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.ruleForm.ages[k][0] +
                                  "岁 至 " +
                                  _vm.ruleForm.ages[k][1] +
                                  "岁"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delFormItem("ages", k)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "fans-manage-item" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "gender", $$v)
                            },
                            expression: "ruleForm.gender",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "both" } }, [
                            _vm._v("全部性别"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "male" } }, [
                            _vm._v("男"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "female" } }, [
                            _vm._v("女"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "上限设置" } },
            [
              _c(
                "el-card",
                {
                  attrs: {
                    "body-style": "background: #f7f8fa",
                    shadow: "never",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "fans-manage-item" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            disabled: _vm.showMax,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addFormItem("max")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        添加强导上限\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showMax
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "fans-manage-item" },
                            [
                              _c("el-col", { attrs: { span: 5 } }, [
                                _c("span", [_vm._v("当模型订单超过")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "o_sum" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "fans-manage-order",
                                        model: {
                                          value: _vm.ruleForm.o_sum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "o_sum",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "ruleForm.o_sum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 5 } }, [
                                _vm._v("单，订单去到："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            multiple: "",
                                            "collapse-tags": "",
                                            placeholder: "请选择",
                                            width: "100px",
                                          },
                                          on: {
                                            change: _vm.limitPatternRatioChange,
                                          },
                                          model: {
                                            value: _vm.limitPatternRatio,
                                            callback: function ($$v) {
                                              _vm.limitPatternRatio = $$v
                                            },
                                            expression: "limitPatternRatio",
                                          },
                                        },
                                        _vm._l(_vm.patterns, function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "limitPatternsList" } },
                            _vm._l(_vm.limitPatternsList, function (v) {
                              return _c(
                                "el-form-item",
                                { key: v.id, staticClass: "fans-manage-item" },
                                [
                                  _c(
                                    "el-col",
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(v.value) +
                                          "，占比：\n                                "
                                      ),
                                      _c("el-input", {
                                        staticClass: "fans-manage-order",
                                        staticStyle: { width: "70px" },
                                        model: {
                                          value: v.ratios,
                                          callback: function ($$v) {
                                            _vm.$set(v, "ratios", _vm._n($$v))
                                          },
                                          expression: "v.ratios",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.ruleForm.remark === "问先生强导模型"
            ? _c(
                "el-form-item",
                { attrs: { label: "强导设置" } },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": "background: #f7f8fa",
                        shadow: "never",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "fans-manage-item",
                          attrs: {
                            label: "年龄导粉比例",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    multiple: "",
                                    "collapse-tags": "",
                                    placeholder: "选择年龄段",
                                    width: "100px",
                                  },
                                  on: { change: _vm.ageRatioChange },
                                  model: {
                                    value: _vm.ageRatio,
                                    callback: function ($$v) {
                                      _vm.ageRatio = $$v
                                    },
                                    expression: "ageRatio",
                                  },
                                },
                                _vm._l(_vm.ageRatioOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.ageRatioList, function (item) {
                        return _c(
                          "el-form-item",
                          {
                            key: item.age,
                            staticClass: "fans-manage-item",
                            attrs: {
                              prop: "age_tag",
                              rules: [
                                {
                                  data: item,
                                  validator: _vm.validateAgeTag,
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("el-col", { attrs: { span: 2 } }, [
                              _vm._v(" " + _vm._s(item.age) + "后 "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _vm._v(
                                  "\n                        app占比：\n                        "
                                ),
                                _c("el-input", {
                                  staticClass: "fans-manage-order",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: item.ratios.app,
                                    callback: function ($$v) {
                                      _vm.$set(item.ratios, "app", _vm._n($$v))
                                    },
                                    expression: "item.ratios.app",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _vm._v(
                                  "\n                        企微占比：\n                        "
                                ),
                                _c("el-input", {
                                  staticClass: "fans-manage-order",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: item.ratios.qiwei,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item.ratios,
                                        "qiwei",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "item.ratios.qiwei",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _vm._v(
                                  "\n                        场景码：\n                        "
                                ),
                                _c("el-input", {
                                  staticClass: "fans-manage-order",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: item.scene_id,
                                    callback: function ($$v) {
                                      _vm.$set(item, "scene_id", _vm._n($$v))
                                    },
                                    expression: "item.scene_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }