"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var _yunying = require("@/api/yunying");
var _validate = require("@/utils/validate");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
var _api = require("../api");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    manageType: {},
    templateList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    formData: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          flag: '',
          app_id: '',
          production_url: '',
          sandbox_url: ''
        };
      }
    }
  },
  data: function data() {
    var validateUrl = function validateUrl(rule, value, callback) {
      if (value && !(0, _validate.isCorrectUrl)(value)) {
        callback(new Error('链接格式不正确'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      dialogVisible: false,
      rules: {
        template_id: {
          required: true,
          message: '请选择配置模板',
          trigger: 'blur'
        },
        title: {
          required: true,
          message: '请输入页面名称',
          trigger: 'blur'
        },
        flag: {
          required: true,
          message: '请选择页面标识',
          trigger: 'change'
        },
        app_id: {
          required: true,
          message: '请选择h5应用产品',
          trigger: 'change'
        },
        production_url: {
          required: false,
          validator: validateUrl,
          trigger: 'blur'
        },
        sandbox_url: {
          required: false,
          validator: validateUrl,
          trigger: 'blur'
        }
      },
      templateFlagMap: new Map()
    };
  },
  mounted: function mounted() {
    this.getAllProducts();
    this.getAllQmProducts();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    products: function products(state) {
      var originProducts = [];
      var appType = this.getAppType();
      switch (appType) {
        case 'zxcs':
          originProducts = _lodash.default.cloneDeep(state.common.all_products);
          break;
        case 'qm':
          originProducts = _lodash.default.cloneDeep(state.common.all_qm_products);
      }
      var products = originProducts ? originProducts.filter(function (item) {
        return !!item.app_id;
      }) : [];
      return products;
    }
  })), {}, {
    flagOption: function flagOption() {
      return this.templateFlagMap.get(this.formData.template_id) || [];
    }
  }),
  watch: {
    dialogVisible: function dialogVisible(val, oldVal) {
      if (val && val !== oldVal) {
        this.getPageFlag();
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    getAllProducts: 'common/getAllProducts',
    getAllQmProducts: 'common/getAllQmProducts'
  })), {}, {
    // 获取标识列表
    getPageFlag: function getPageFlag() {
      var _this = this;
      if (!this.formData.template_id) {
        return;
      }
      if (this.templateFlagMap.has(this.formData.template_id)) {
        return;
      }
      (0, _api.getTemplateFlag)(this.formData.template_id).then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code == 200) {
          var flagMap = new Map(_this.templateFlagMap);
          flagMap.set(_this.formData.template_id, data);
          _this.templateFlagMap = flagMap;
        }
      });
    },
    // 提交数据
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var formData = _this2.formData,
            manageType = _this2.manageType;
          if (manageType === 'add') {
            _this2.loading = true;
            var app_id = formData.app_id;
            var selectApp = _this2.products.find(function (item) {
              return item.app_id === app_id;
            });
            _this2.createPageHandle(_objectSpread(_objectSpread({}, formData), {}, {
              name: selectApp.name
            }));
          } else {
            _this2.updatePageInfo(formData);
          }
        }
      });
    },
    // 新建页面
    createPageHandle: function createPageHandle(formData) {
      var _this3 = this;
      (0, _yunying.createPage)(formData).then(function (_ref2) {
        var data = _ref2.data;
        _this3.loading = false;
        if (data) {
          _this3.$alert('页面创建成功, 是否立马前往进行资源配置?', '提示', {
            confirmButtonText: '确定',
            callback: function callback(action) {
              _this3.dialogVisible = false;
              if (action == 'confirm') {
                _this3.$emit('upDateList');
                var name = _this3.$route.name;
                _this3.$router.push({
                  name: 'config',
                  query: {
                    page_id: data,
                    flag: formData.flag,
                    modules: name,
                    template_id: formData.template_id
                  }
                });
              } else {
                _this3.$emit('upDateList');
              }
            }
          });
        }
      }).catch(function (_ref3) {
        var err = _ref3.err;
        _this3.loading = false;
        console.log(err);
      });
    },
    // 修改页面
    updatePageInfo: function updatePageInfo(formData) {
      var _this4 = this;
      var params = {
        page_id: formData.id,
        title: formData.title,
        production_url: formData.production_url,
        sandbox_url: formData.sandbox_url
      };
      (0, _yunying.updatePageInfo)(params).then(function (_ref4) {
        var data = _ref4.data;
        _this4.$message({
          message: '修改成功',
          type: 'success'
        });
        _this4.dialogVisible = false;
        // 更新数据
        _this4.$emit('upDateList');
      });
    },
    selectType: function selectType() {
      this.formData.app_id = '';
    },
    onTemplateIdChange: function onTemplateIdChange(val) {
      this.getPageFlag();
      // 数据重置
      this.formData.flag = '';
      this.formData.app_id = '';
    },
    getAppType: function getAppType() {
      var _this5 = this;
      // cs_type，产品类别，1-测算，2-起名
      var isQm = this.templateList.some(function (item) {
        return item.template_id === _this5.formData.template_id && item.cs_type === 2;
      });
      return isQm ? 'qm' : 'zxcs';
    }
  })
};