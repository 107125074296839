"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
var _recommended = require("@/api/recommended");
var _lodash = _interopRequireDefault(require("lodash"));
var _manage = _interopRequireDefault(require("./components/manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Manage: _manage.default
  },
  data: function data() {
    return {
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      perPage: 15,
      totalNumber: 0,
      // 总数量
      loading: true,
      mapData: [],
      // 使用映射内容
      thePage: [],
      // 页面
      selectedPage: '',
      // 选择的页面
      resourceNiche: [],
      // 资源位
      selectedResource: '',
      // 选择的资源位id
      resourceValue: '',
      // 特殊产品收到输入的资源位ID
      manageType: '',
      chooseData: {}
    };
  },
  filters: {
    getPage: function getPage(val, mapData) {
      if (val && mapData.length) {
        var result = mapData.find(function (v) {
          if (v.position === val) {
            return v;
          }
        });
        return result ? result.title : '';
      }
    },
    getResource: function getResource(val, mapData, selectedPage) {
      if (val && mapData.length && selectedPage) {
        var result = '';
        mapData.find(function (v) {
          if (v.position === selectedPage && v.child.length) {
            v.child.find(function (vi) {
              if (val === vi.product_id) {
                result = vi;
              }
            });
          }
        });
        return result ? result.title : '';
      }
    }
  },
  mounted: function mounted() {
    this.getMap();
  },
  methods: {
    getMap: function getMap() {
      var _this = this;
      (0, _recommended.getResourceMap)().then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code === 200) {
          var resultData = data.data;
          var arr1 = [];
          var arr2 = [];

          //  value 用于展示
          //  label 用于传递
          resultData.forEach(function (v) {
            arr1.push({
              value: v.title,
              label: v.position
            });
          });
          resultData[0].child.forEach(function (v) {
            arr2.push({
              value: v.title,
              label: v.product_id
            });
          });
          _this.mapData = resultData; // 全部映射数据
          _this.thePage = arr1; // 页面数据
          _this.resourceNiche = arr2; // 资源位数据
          _this.selectedPage = arr1[0].label; // 默认选中的页面
          _this.selectedResource = arr2[0].label; // 默认选中的资源位
          _this.getList();
        }
      }).catch(function () {
        _this.loading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      if (!this.selectedPage) {
        this.$message.error('请选择页面');
        return;
      }
      if (this.selectedPage && !this.selectedResource && !this.resourceValue) {
        this.$message.error('特殊产品需要手动填入资源位模块ID');
        return;
      }
      if (!this.selectedResource && !this.resourceValue) {
        this.$message.error('请选择资源位模块');
        return;
      }
      this.loading = true;
      var params = {
        position: this.selectedPage,
        product_id: this.selectedResource || this.resourceValue,
        page: this.currentPage,
        per_page: this.perPage
      };
      (0, _recommended.getResourceList)(params).then(function (_ref2) {
        var code = _ref2.code,
          data = _ref2.data;
        _this2.loading = false;
        if (code === 200) {
          var list = data.list,
            meta = data.meta;
          _this2.tableData = list;
          _this2.totalPage = meta.page.total_page;
          _this2.totalNumber = meta.page.total;
        }
      }).catch(function () {
        _this2.loading = false;
      });
    },
    pageChange: function pageChange(val) {
      if (!val) return;
      var arr = [];
      this.mapData.find(function (v) {
        if (val === v.position) {
          v.child.forEach(function (vi) {
            arr.push({
              value: vi.title,
              label: vi.product_id
            });
          });
        }
      });
      this.resourceNiche = arr;
      this.selectedResource = arr[0].label;
    },
    onSearch: function onSearch() {
      this.getList();
    },
    onAdd: function onAdd() {
      if (!this.selectedResource && !this.resourceValue) {
        this.$message.error('请填入资源位模块ID后继续');
        return;
      }
      this.$refs.managePop.dialogVisible = true;
      this.manageType = 'add';
      this.chooseData = {};
    },
    onModify: function onModify(val) {
      this.$refs.managePop.dialogVisible = true;
      this.manageType = 'edit';
      this.chooseData = _lodash.default.cloneDeep(val);
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.loading = true;
      this.getList();
    },
    onCopy: function onCopy() {
      this.$message({
        type: 'success',
        message: '复制成功!',
        duration: 1000
      });
    },
    onError: function onError() {
      this.$message.error('复制失败!');
    }
  }
};