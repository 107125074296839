var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "width-200",
                  attrs: { filterable: "", placeholder: "页面" },
                  on: { change: _vm.pageChange },
                  model: {
                    value: _vm.selectedPage,
                    callback: function ($$v) {
                      _vm.selectedPage = $$v
                    },
                    expression: "selectedPage",
                  },
                },
                _vm._l(_vm.thePage, function (item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.value, value: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "width-200",
                  attrs: { filterable: "", placeholder: "资源位模块" },
                  model: {
                    value: _vm.selectedResource,
                    callback: function ($$v) {
                      _vm.selectedResource = $$v
                    },
                    expression: "selectedResource",
                  },
                },
                _vm._l(_vm.resourceNiche, function (item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.value, value: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.selectedPage && !_vm.selectedResource
                ? _c("el-input", {
                    staticClass: "width-200",
                    attrs: { placeholder: "资源位模块", clearable: "" },
                    model: {
                      value: _vm.resourceValue,
                      callback: function ($$v) {
                        _vm.resourceValue = $$v
                      },
                      expression: "resourceValue",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search", plain: "" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("\n                搜索\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus", plain: "" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("\n                增加\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "title-tips" },
        [
          _c("el-tag", { attrs: { type: "danger" } }, [
            _vm._v("特殊产品ID,需要手动填写对应的资源位模块ID,例如default等等"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "序号", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "sort", label: "排序", align: "center" },
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { prop: "", label: "页面", align: "center" } },
            [_vm._v(_vm._s(_vm._f("getPage")(_vm.selectedPage, _vm.mapData)))]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { prop: "", label: "资源位模块", align: "center" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("getResource")(
                      _vm.selectedResource,
                      _vm.mapData,
                      _vm.selectedPage
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "url", label: "测算链接", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-link", {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: scope.row.zxcs_domain + scope.row.url,
                          expression: "scope.row.zxcs_domain + scope.row.url",
                          arg: "copy",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:error",
                          value: _vm.onError,
                          expression: "onError",
                          arg: "error",
                        },
                      ],
                      staticClass: "el-icon-document-copy",
                      attrs: { type: "primary" },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.url))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "标题", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "desc", label: "文案", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "people_nums", label: "测过人数", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "comment_nums", label: "评论人数", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "show_type", label: "展示方式", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "上架状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            scope.row.status === "enable"
                              ? "success"
                              : "warning",
                          "disable-transitions": "",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.status === "enable" ? "启用" : "禁用"
                          ) + "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "favorable_rate", label: "好评率", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "icon", label: "icon", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "80px", height: "80px" },
                      attrs: { src: scope.row.icon, fit: "contain" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "banner", label: "banner", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "auto", height: "80px" },
                      attrs: { src: scope.row.banner, fit: "contain" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", fixed: "right", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onModify(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalPage > 1
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.perPage,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("manage", {
        ref: "managePop",
        attrs: {
          ruleForm: _vm.chooseData,
          manageType: _vm.manageType,
          selectedPage: _vm.selectedPage,
          selectedResource: _vm.selectedResource || _vm.resourceValue,
        },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }