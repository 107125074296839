"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _toConsumableArray2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.object.freeze");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _yunying = require("@/api/yunying");
var _constants = require("@/constants");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          wechatId: [],
          percent: []
        };
      }
    },
    type: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    manageType: {}
  },
  created: function created() {
    this.FANS_TYPES_MAP = _constants.FANS_TYPES_MAP;
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      rules: {
        name: {
          required: true,
          message: '请输入标识',
          trigger: 'blur'
        },
        wechatId: {
          required: true,
          message: '请选择导粉号',
          trigger: 'blur'
        },
        percent: {
          required: true,
          type: 'array',
          trigger: 'blur',
          validator: function validator(rule, value, callback) {
            var val = 0;
            value.map(function (v) {
              return val += Number(v);
            });
            if (value.indexOf('0') > -1 || value.indexOf(0) > -1) {
              callback(new Error('请填写正确权重，单项权重不可为0'));
            } else if (val !== 100) {
              callback(new Error('请填写正确权重，总值为100'));
            } else {
              callback();
            }
          }
        }
      },
      wechats: [],
      huokes: []
    };
  },
  mounted: function mounted() {
    this.getWxComponentList();
  },
  methods: {
    // 获取导粉组件列表
    getWxComponentList: function getWxComponentList() {
      var _this = this;
      var params = {
        per_page: 2000,
        page: 1
      };
      var huokeParams = _objectSpread(_objectSpread({}, params), {}, {
        type: '3,4'
      });
      var otherParams = _objectSpread(_objectSpread({}, params), {}, {
        type: '1,2,3,5'
      });
      (0, _yunying.getWxList)(otherParams).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          _this.wechats = Object.freeze(data.list);
        }
      });
      (0, _yunying.getWxList)(huokeParams).then(function (_ref2) {
        var data = _ref2.data,
          code = _ref2.code;
        if (code === 200) {
          _this.huokes = Object.freeze(data.list);
        }
      });
    },
    // 应用远程搜索
    remoteMethod: function remoteMethod(query) {
      if (query !== '') {
        this.loading2 = true;
        var flag = /^[A-Za-z0-9]+$/.test(query);
        if (flag) {
          this.getH5Applist('', query);
        } else {
          this.getH5Applist(query, '');
        }
      }
    },
    // 导粉选择监听，便于展示权重输入框
    componentSelect: function componentSelect(type, val) {
      if (type === 'wechat') {
        this.ruleForm.percent = (0, _toConsumableArray2.default)(Array(val.length));
      }
    },
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this3 = this;
      var _this$ruleForm = this.ruleForm,
        percent = _this$ruleForm.percent,
        wechatId = _this$ruleForm.wechatId,
        id = _this$ruleForm.id,
        name = _this$ruleForm.name;
      if (percent.length === 1) {
        percent[0] = 100;
      }
      var data = {
        name: name,
        wechat_group: wechatId.join(','),
        weight: percent.map(Number).join(',')
      };
      if (this.manageType === 'add') {
        (0, _yunying.addWxGroup)(data).then(function (_ref3) {
          var code = _ref3.code;
          if (code === 200) {
            _this3.$message({
              message: '添加成功',
              type: 'success'
            });
            _this3.dialogVisible = false;
            _this3.$parent.getWxConfigList(); // 更新父组件数据
          }
        });
      } else {
        (0, _yunying.modifyWxGroup)(id, data).then(function () {
          _this3.$message({
            message: '修改成功',
            type: 'success'
          });
          _this3.dialogVisible = false;
          _this3.$parent.getWxConfigList(); // 更新父组件数据
        });
      }
    }
  }
};